
@import 'theme/variable' ;
$primary: #9700a6;
$secondary: #676767;
$dark: #000;
$light:#000;
@import 'theme/style';
@import 'theme/responsive';
@import 'theme/customize';
@import 'theme/theme-dark';
@import 'theme/theme-rtl';