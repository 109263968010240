/* Follow me for more pens like this! */


// Tweak these to change the style of the toggle
$toggle-background-color-on: #191f3c;
$toggle-background-color-off: #f5f5f8;
$toggle-control-color: white;
$toggle-width: 40px;
$toggle-height: 25px;
$toggle-gutter: 5px;
$toggle-radius: 50%;
$toggle-control-speed: .125s;
$toggle-control-ease: ease-in;

// These are our computed variables
// change at your own risk.
$toggle-radius: $toggle-height / 2;
$toggle-control-size: $toggle-height - ($toggle-gutter * 2);

.toggle-control {
  display: block;
  position: relative;
  padding-left: $toggle-width;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .control {
    background-color: $toggle-background-color-on;
    border:1px solid  #ffffff;
    &::before {
      background: $toggle-background-color-on;
      left: $toggle-width - ($toggle-control-size * 1.4) - $toggle-gutter;
      transform: scale(1);
    }
    
    &::after {
      background:#9700a6;
      left: $toggle-width - $toggle-control-size - $toggle-gutter;
    }
  }

  .control {
    position: absolute;
    top: 0;
    left: 0;
    height: $toggle-height;
    width: $toggle-width;
    border-radius: $toggle-radius;
    background-color: $toggle-background-color-off;
    transition: background-color $toggle-control-speed $toggle-control-ease;
    border:1px solid  #9700a6;
    
    &::before {
      content: "";
      position: absolute;
      width: $toggle-control-size / 1;
      height: $toggle-control-size / 1;
      left: 0px;
      top: 0px;
      border-radius: 50%;
      
      transform: scale(0);
      
      background: $toggle-background-color-off;
      z-index: 5;
      
      transition: all $toggle-control-speed $toggle-control-ease;
    }
    
    &::after {
      content: "";
      position: absolute;
      left: $toggle-gutter;
      top: $toggle-gutter;
      width: $toggle-control-size;
      height: $toggle-control-size;
      border-radius: $toggle-radius;
      background: #ddac27;
      transition: all $toggle-control-speed $toggle-control-ease;
    }
  }
}


